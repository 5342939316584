<template>
    <v-select
        ref="select"
        class="app-select"
        :small-chips="chips"
        v-bind="$attrs"
        :value="value"
        :multiple="multiple"
        menu-props="offset-y, offset-overflow"
        :deletable-chips="chips"
        @change="onChange"
        @click:append="onArrowClick"
    />
</template>

<script>
export default {
    name: 'AppSelect',

    props: {
        value: {
            type: [Array, String, Boolean],
            default: () => [],
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        chips: {
            type: Boolean,
            default: true,
        },
    },

    watch: {
        items() {
            if (this.multiple) {
                this.$emit('input', []);
            } else {
                this.$emit('input', null);
            }
        },
    },

    methods: {
        onArrowClick() {
            if (this.$refs.select.isFocused) {
                this.$refs.select.blur();
                this.$refs.select.isMenuActive = false;
            } else {
                this.$refs.select.focus();
                this.$refs.select.isMenuActive = true;
            }
        },

        onChange(ev) {
            if (!ev || !ev.length) {
                this.$refs.select.blur();
                this.$refs.select.isMenuActive = false;
            }

            this.$emit('input', ev);
        },
    },
};
</script>

<style scoped></style>