<template>
    <div>
        <v-card class="py-6 px-4 px-sm-6">
            <div class="d-flex flex-wrap align-center justify-space-beetwen mb-10">
                <h3 class="title flex-grow-1 mb-5 mb-sm-0 text-center text-sm-left">
                    {{ inEditMode ? 'Edit Asset' : 'New Asset' }}
                </h3>
                <v-select
                    v-if="!inEditMode"
                    v-model="currentTypeAsset"
                    :items="types"
                    item-text="value"
                    item-value="value"
                    label="Asset Type"
                    prepend-icon="mdi-file-document-outline"
                    hide-details="auto"
                    dense
                    class="mb-sm-0 mx-auto ml-sm-5 mr-sm-0"
                    style="max-width: 250px; opacity: 0.65;"
                />
            </div>

            <v-form
                ref="form"
                class="px-sm-6"
            >
                <template v-if="!inEditMode || inEditMode && asset.images">
                    <asset-general-form
                        :title="asset.title"
                        :address="asset.address"
                        :description="asset.description"
                        :open-house-dates="asset.openHouseDates"
                    />

                    <asset-gallery :images="asset.images" />

                    <template v-if="$store.state.Asset.kind !== 'Basic'">
                        <asset-features-form :features="asset.data.features" />
                        <asset-interior-form
                            v-if="$store.state.Asset.kind !== 'Land'"
                            :interior="asset.data.interior"
                        />
                        <asset-exterior-form
                            v-if="$store.state.Asset.kind !== 'Land'"
                            :exterior="asset.data.exterior"
                        />
                    </template>

                    <asset-documents :documents="asset.documents" />

                    <admin-dashboard-actions
                        :submit-label="inEditMode ? 'update' : 'save'"
                        :asset-status="asset.status"
                        @publish="validate"
                        @cancel="$router.push('/assets')"
                    >
                        <div
                            v-if="inEditMode"
                            class="d-flex"
                        >
                            <template v-if="showDeleteButton && !isDeleted">
                                <v-tooltip
                                    v-if="disableDelete"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <div
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-btn
                                                color="error"
                                                class="mr-3"
                                                disabled
                                            >
                                                archive asset
                                            </v-btn>
                                        </div>
                                    </template>
                                    <span class="font-weight-bold">
                                        The asset cannot be archived until the sale is stopped
                                    </span>
                                </v-tooltip>
                                <v-btn
                                    v-else
                                    color="error"
                                    class="mr-3"
                                    :disabled="disableDelete"
                                    @click="onDelete"
                                >
                                    archive asset
                                </v-btn>
                            </template>

                            <v-tooltip
                                v-if="disableDelete"
                                top
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-btn
                                            color="error"
                                            text
                                            disabled
                                        >
                                            delete asset
                                        </v-btn>
                                    </div>
                                </template>
                                <span class="font-weight-bold">The asset cannot be deleted until the sale is stopped</span>
                            </v-tooltip>
                            <v-btn
                                v-else
                                color="error"
                                text
                                :disabled="disableDelete"
                            >
                                delete asset
                            </v-btn>
                        </div>
                    </admin-dashboard-actions>
                </template>

                <div
                    v-else
                    class="text-center my-16"
                >
                    <v-progress-circular
                        class="mx-auto"
                        indeterminate
                        size="48"
                    />
                </div>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import AssetGeneralForm from './AssetGeneralForm';
import AssetFeaturesForm from './AssetFeaturesForm';
import AdminDashboardActions from '@/components/AdminDashboard/AdminDashboardActions';
import AssetGallery from './AssetGallery/index';
import AssetDocuments from './AssetDocuments';
import AssetExteriorForm from './AssetExteriorForm';
import AssetInteriorForm from './AssetInteriorForm';
import { EVENT_BUS_EVENTS, EventBus } from '@/event-bus';
import assetApi from '@/services/apiCalls/asset';
import { DELETED } from '@/utils/filters';
import { saleStatuses } from '@/constants/saleStatuses';

export default {
    name: 'AssetForm',

    components: {
        AssetInteriorForm,
        AssetExteriorForm,
        AssetDocuments,
        AssetGallery,
        AdminDashboardActions,
        AssetFeaturesForm,
        AssetGeneralForm,
    },

    data() {
        return {
            inEditMode: !!this.$route.params.id,
            currentTypeAsset: 'Basic',
            types: [
                {
                    value: 'Basic',
                    icon: 'mdi-file-document-outline',
                },
                {
                    value: 'Residential',
                    icon: 'mdi-file-document-outline',
                },
                {
                    value: 'Commercial',
                    icon: 'mdi-file-document-outline',
                },
                {
                    value: 'Land',
                    icon: 'mdi-file-document-outline',
                },
            ],

            asset: {
                data: {
                    features: {},
                    interior: {},
                    exterior: {},
                },
            },
        };
    },

    computed: {
        assetSale() {
            return this.asset.sale;
        },
        showDeleteButton() {
            return this.inEditMode && this.asset?.status !== DELETED;
        },
        disableDelete() {
            if (!this.asset.sale) return false;

            return [saleStatuses.PLANNING, saleStatuses.PLANNED, saleStatuses.ACTIVE_AUCTION, saleStatuses.ACTIVE].includes(
                this.asset.sale.status,
            );
        },
        isDeleted() {
            return this.asset.status === DELETED;
        },
    },

    watch: {
        currentTypeAsset(value) {
            this.setAssetKind(value);
        },
    },

    async created() {
        this.currentTypeAsset = this.$store.state.Asset.kind;
        if (this.$route.params.id) {
            try {
                const { id, kind } = this.$route.params;

                const { data } = await assetApi.getAssetById(id, kind);
                this.asset = data[`get${kind}Asset`];
                this.setAssetKind(kind);
            } catch (e) {
                this.$store.dispatch('handleError', e);

                this.$router.push('/assets?tab=Draft');
            }
        } else {
            await this.$store.dispatch('Asset/fetchOptions');
        }
    },

    destroyed() {
        this.$store.commit('Asset/resetState');
    },

    methods: {
        async setAssetKind(kind) {
            this.$store.commit('Asset/setAssetKind', kind);
            await this.$store.dispatch('Asset/fetchOptions');
            this.$refs.form.resetValidation();
        },

        async validate() {
            const isValid = await this.$refs.form.validate();

            if (isValid) {
                this.createAsset();
            } else {
                this.$store.commit('snackbar', {
                    text: 'Please, fill out all required fields',
                    color: 'error',
                    isOpened: true,
                });
            }
        },

        createAsset() {
            EventBus.$emit(EVENT_BUS_EVENTS.SET_ASSET_DATA);
            this.$store.dispatch('Asset/createAsset', this.$route.params.id);
        },

        onDelete() {
            this.$store.dispatch('Asset/deleteAsset', {
                id: this.$route.params.id,
                prevStatus: this.asset.status,
            });
        },
    },
};
</script>
