export const RESIDENTIAL_INTERIOR_FORM = [
    [
        [
            {
                label: 'Kitchen Features',
                cols: 6,
                type: 'select',
                key: 'kitchenFeatures',
            },
        ],
        [
            {
                label: 'Appliances',
                cols: 12,
                type: 'select',
                key: 'appliances',
            },
        ],
        [
            {
                label: 'Flooring',
                cols: 12,
                type: 'select',
                key: 'flooring',
            },
        ],
        [
            {
                label: 'Windows',
                cols: 12,
                type: 'select',
                key: 'windows',
            },
        ],
    ],
    [
        [
            {
                label: 'Bedroom Features',
                cols: 6,
                type: 'select',
                key: 'bedroomFeatures',
            },
            {
                label: 'Other Rooms',
                type: 'select',
                cols: 6,
                key: 'otherRooms',
            },
        ],
        [
            {
                label: 'Bathroom Features',
                cols: 6,
                type: 'select',
                key: 'bathroomFeatures',
            },
            {
                label: 'Other Features',
                type: 'select',
                cols: 6,
                key: 'otherFeatures',
            },
        ],
        [
            {
                label: 'Master Bedroom Features',
                cols: 6,
                type: 'select',
                key: 'masterBedroomFeatures',
            },
            {
                label: 'Fireplace(s)',
                cols: 2,
                type: 'number',
                integer: true,
                colClass: 'pr-0',
                key: 'fireplace',
                prependIcon: 'mdi-fireplace',
            },
            {
                cols: 4,
                colClass: 'pl-1',
                type: 'select',
                key: 'fireplaceUnit',
            },
        ],
        [
            {
                label: 'Basement Features',
                type: 'select',
                cols: 6,
                key: 'basementFeatures',
            },
            {
                label: 'Handicap Amenities',
                type: 'select',
                cols: 6,
                key: 'handicapAmenities',
            },
        ],
    ],
];

export const COMMERCIAL_INTERIOR_FORM = [
    [
        [
            {
                label: 'Total Rooms',
                cols: 3,
                type: 'number',
                integer: true,
                key: 'totalRooms',
                prependIcon: 'mdi-home',
            },
            {
                label: 'Flooring',
                cols: 6,
                offset: 3,
                type: 'select',
                key: 'flooring',
            },
        ],
        [
            {
                label: 'Total Bedrooms',
                cols: 3,
                type: 'number',
                integer: true,
                key: 'totalBedrooms',
                prependIcon: 'mdi-bed',
            },
            {
                label: 'Appliances',
                cols: 6,
                offset: 3,
                type: 'select',
                key: 'appliances',
            },
        ],
        [
            {
                label: 'Total Bathrooms',
                cols: 3,
                type: 'number',
                integer: true,
                key: 'totalBathrooms',
                customPrependIcon: 'bath',
            },
            {
                label: 'Windows',
                cols: 6,
                offset: 3,
                type: 'select',
                key: 'windows',
            },
        ],
        [
            {
                label: 'Total Public Restrooms',
                cols: 3,
                type: 'number',
                integer: true,
                key: 'totalPublicRestrooms',
                customPrependIcon: 'public-restrooms',
            },
            {
                label: 'Interior Features',
                cols: 6,
                offset: 3,
                type: 'select',
                key: 'interiorFeatures',
            },
        ],
        [
            {
                label: 'Ceiling Height',
                cols: 3,
                type: 'number',
                key: 'ceilingHeight',
                suffix: 'Ft',
                prependIcon: 'mdi-arrow-expand-vertical',
            },
            {
                label: 'Basement Features',
                type: 'select',
                cols: 6,
                offset: 3,
                key: 'basementFeatures',
            },
        ],
        [
            {
                label: 'Handicap Amenities',
                type: 'select',
                cols: 6,
                offset: 6,
                key: 'handicapAmenities',
            },
        ],
    ],
];
