<template>
    <v-dialog
        :value="dialog"
        width="550"
        persistent
    >
        <v-card class="pa-4 pt-2">
            <div class="d-flex align-center justify-space-between">
                <h2>
                    Video Embedding

                    <v-tooltip right>
                        <template #activator="{ on }">
                            <v-icon
                                color="black"
                                class="ml-3"
                                size="18"
                                style="cursor: pointer"
                                v-on="on"
                            >
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                        You can embed videos from YouTube and Vimeo here. <br>
                        Find the embed code for your video and paste it below.
                    </v-tooltip>
                </h2>

                <v-icon
                    size="20"
                    color="gray lighten-3"
                    @click="$emit('close-dialog')"
                >
                    mdi-close
                </v-icon>
            </div>

            <v-form
                ref="form"
                v-model="valid"
                class="mt-3"
                lazy-validation
            >
                <v-row>
                    <v-col cols="8">
                        <v-textarea
                            v-model="embedCode"
                            label="Embed Code"
                            :rules="rules"
                        />
                    </v-col>
                </v-row>
            </v-form>

            <v-row
                class="mt-10 px-4"
                justify="end"
                align="center"
            >
                <div class="d-flex justify-end">
                    <v-btn
                        text
                        @click="closeDialog"
                    >
                        cancel
                    </v-btn>

                    <v-btn
                        color="primary"
                        :loading="loading"
                        class="ml-3"
                        @click="embed"
                    >
                        embed
                    </v-btn>
                </div>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import { validators } from '@/utils/validators';
import assetApi from '@/services/apiCalls/asset';
import { Utils } from '@/utils';
import { Storage } from 'aws-amplify';

export default {
    name: 'AssetVideoEmbeddingDialog',

    props: {
        dialog: {
            type: Boolean,
        },
    },

    data() {
        return {
            embedCode: null,
            valid: false,
            loading: false,
            rules: [(v) => validators.required(v)],
        };
    },

    methods: {
        async embed() {
            await this.$refs.form.validate();

            if (this.valid) {
                await this.parseVideoUrlAndThumbnail();
            }
        },

        closeDialog() {
            this.embedCode = null;
            this.$refs.form.resetValidation();
            this.$emit('close-dialog');
        },

        async parseVideoUrlAndThumbnail() {
            try {
                this.loading = true;
                const { data } = await assetApi.parseVideoEmbed(this.embedCode);
                const res = data.parseVideoEmbed;

                if (!res.url) {
                    this.$store.dispatch('handleError', 'Error while parsing code');

                    return;
                }

                const src = await Storage.get(Utils.extractFileKey(res.thumbnail), {
                    level: 'public',
                });

                this.$emit('embed', {
                    url: res.url,
                    src,
                    thumbnail: res.thumbnail,
                });
                this.closeDialog();
            } catch (error) {
                console.error(error);
                this.$store.dispatch('handleError', error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>