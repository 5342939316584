<template>
    <div class="mb-10">
        <h3>
            Gallery

            <v-tooltip right>
                <template #activator="{ on }">
                    <v-icon
                        color="black"
                        class="ml-2"
                        size="18"
                        style="cursor: pointer"
                        v-on="on"
                    >
                        mdi-help-circle-outline
                    </v-icon>
                </template>
                <p class="mb-0">
                    Drag and drop photos to change <br>
                    their order
                </p>
            </v-tooltip>
        </h3>

        <div class="mt-3 asset-section--content">
            <v-row class="mb-3">
                <v-col
                    class="mb-5"
                    cols="12"
                >
                    <v-row>
                        <v-col 
                            cols="7"
                        >
                            <p>Upload</p>
                            <app-dropzone
                                class="d-flex justify-center align-center w-full bg-grey img-action"
                                @drop="onDrop"
                            >
                                <v-icon
                                    x-large
                                    class="mr-2"
                                    color="primary"
                                >
                                    mdi-image-plus
                                </v-icon>

                                <input
                                    ref="fileInput"
                                    type="file"
                                    accept="image/*"
                                    style="visibility: hidden; position: absolute; left: -1000px"
                                    @input="onFileSelect"
                                >

                                <div class="d-flex flex-column">
                                    <span class="text-small">Drag and drop,</span>
                                    <span
                                        class="text-link text-small"
                                        @click="$refs.fileInput.click()"
                                    >[browse files]</span>
                                    <span>
                                        or
                                        <span
                                            class="text-link text-small"
                                            @click="showVideoEmbeddingDialog = true"
                                        >[embed video]</span>
                                    </span>
                                    <span class="text-small">Preferred image proportion is 3x4</span>
                                </div>
                            </app-dropzone>
                        </v-col>
                        <v-col
                            cols="5"
                            class="img-action"
                        >
                            <p>Deleted</p>

                            <div class="img-action--block border-grey d-flex justify-center align-center">
                                <v-icon
                                    x-large
                                    color="secondary"
                                >
                                    mdi-trash-can
                                </v-icon>

                                <draggable
                                    :list="files.deleted"
                                    group="images"
                                    ghost-class="ghost-small"
                                    class="pa-2 d-flex flex-wrap"
                                    style="width: 100%; height: 100%"
                                    draggable=".draggable"
                                    @start="dragover = true"
                                    @end="dragover = false"
                                    @move="onChange"
                                    @remove="onChange"
                                >
                                    <div
                                        v-for="(media, i) in files.deleted"
                                        :key="i + 'deleted'"
                                        style="width: 70px; height: fit-content"
                                        class="mr-2 draggable"
                                    >
                                        <asset-gallery-media
                                            :src="media.src"
                                            :is-video="media.type === 'Video'"
                                        />
                                    </div>
                                </draggable>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col
                    class="mt-1"
                    cols="12"
                >
                    <v-row class="mb-2">
                        <v-col
                            cols="6"
                            sm="3"
                        >
                            <p class="ma-0">
                                Primary photo*
                            </p>
                        </v-col>
                    </v-row>
                    <draggable
                        :list="files.list"
                        group="images"
                        ghost-class="ghost-medium"
                        class="row"
                        style="min-height: 116px"
                        draggable=".draggable"
                        @start="dragover = true"
                        @end="dragover = false"
                        @change="onChange"
                        @remove="onChange"
                    >
                        <v-col
                            v-for="(media, i) in files.list"
                            :key="i + 'uploaded'"
                            class="mb-5"
                            :class="{ 'draggable': !!media }"
                            cols="6"
                            sm="3"
                        >
                            <asset-gallery-media
                                v-if="media"
                                :src="media.src"
                                class="gallery-item"
                                :class="{ 'primary-item': i === 0 }"
                                :is-video="media.type === 'Video'"
                            />

                            <div
                                v-else
                                class="placeholder-block gallery-item"
                                :class="{ 'primary-item': i === 0 }"
                            />
                        </v-col>

                        <template
                            v-if="files.list.length < 4"
                        >
                            <v-col
                                v-for="i in 4 - files.list.length"
                                :key="i + 'placeholder'"
                                class="mb-5"
                                cols="6"
                                sm="3"
                            >
                                <div
                                    class="placeholder-block gallery-item"
                                    :class="{ 'primary-item': i === 1 && !files.list.length }"
                                />
                            </v-col>
                        </template>
                    </draggable>
                </v-col>
            </v-row>
        </div>

        <asset-video-embedding-dialog
            :dialog="showVideoEmbeddingDialog"
            @embed="addVideo($event)"
            @close-dialog="showVideoEmbeddingDialog = false"
        />
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { assetMixin } from '@/mixins/assetMixin';
import { Storage } from 'aws-amplify';
import { Utils } from '@/utils';
import AppDropzone from '@/components/App/AppDropzone';
import AssetVideoEmbeddingDialog from '../AssetVideoEmbeddingDialog';
import AssetGalleryMedia from '@/components/Asset/AssetForm/AssetGallery/AssetGalleryMedia';

export default {
    name: 'AssetGallery',

    components: {
        AssetGalleryMedia,
        AssetVideoEmbeddingDialog,
        AppDropzone,
        draggable,
    },

    mixins: [assetMixin],

    props: {
        images: {
            type: Object,
            default: () => {
                return {
                    mainImage: null,
                    firstRaw: [],
                    secondRaw: [],
                };
            },
        },
    },

    data() {
        return {
            showVideoEmbeddingDialog: false,
            dragover: false,
            mainDragOver: false,
            files: {
                list: [],
                deleted: [],
            },
        };
    },

    async created() {
        if (this.images.mainImage) {
            this.downloadImages([
                {
                    url: this.images.mainImage,
                    type: 'Image',
                    thumbnail: null,
                },
            ]).then((res) => {
                this.files.list.unshift(...res);
            });
        }

        const firstRaw = await this.downloadImages(this.images.firstRaw);
        this.files.list.push(...firstRaw);

        const secondRaw = await this.downloadImages(this.images.secondRaw);
        this.files.list.push(...secondRaw);

        this.onChange();
    },

    methods: {
        onDrop(ev) {
            ev.forEach((img) => {
                this.onFileSelect({
                    target: {
                        files: [img],
                    },
                });
            });
        },

        async downloadImages(images) {
            if (!images || !images.length) {
                return [];
            }

            return Promise.all(
                images.map(async (media) => {
                    const srcKey = this.isVideo(media)
                        ? Utils.extractFileKey(media.thumbnail)
                        : Utils.extractFileKey(media.url);

                    const res = await Storage.get(srcKey, { level: 'public' });

                    return {
                        src: res,
                        url: media.url,
                        thumbnail: media.thumbnail,
                        type: media.type,
                        uploaded: true,
                    };
                }),
            );
        },

        isVideo(media) {
            return media && media.type === 'Video';
        },

        onFileSelect(ev) {
            const file = ev.target.files[0];

            if (file) {
                if (!file.type.includes('image')) {
                    this.$store.commit('snackbar', {
                        text: 'Wrong file format',
                        color: 'error',
                        isOpened: true,
                    });

                    return;
                }

                const reader = new FileReader();

                reader.onloadend = () => {
                    this.addMedia(reader.result, 'Image', null, file);
                };

                reader.readAsDataURL(file);
            }
        },

        addVideo({ url, thumbnail, src }) {
            const res = {
                url,
                type: 'Video',
                thumbnail,
                src,
                uploaded: true,
            };

            if (!this.files.list.length && this.isVideo(res)) {
                this.files.list.push(null, res);
                return;
            }

            this.files.list.push(res);
        },

        addMedia(src, type, thumbnail, file) {
            const res = {
                src,
                type,
                thumbnail,
                file,
            };

            if (!this.files.list.length && this.isVideo(res)) {
                this.files.list.push(null, res);
                return;
            } else if (this.files.list[0] === null && !this.isVideo(res)) {
                this.files.list = [res, ...this.files.list.filter(Boolean)];
                return;
            }

            this.files.list.push(res);
        },

        setConfig() {
            const mainImage = this.files.list.length ? this.files.list[0].file || this.files.list[0].url : null;
            let firstRaw = [];
            let secondRaw = [];
            if (this.files.list.length > 1) {
                const middleIdx = Math.floor(this.files.list.length / 2) + 1;
                firstRaw = this.files.list.slice(1, middleIdx);
                secondRaw = this.files.list.slice(middleIdx, this.files.list.length);
            }

            this.$store.commit('Asset/setMediaFiles', {
                mainImage,
                firstRaw,
                secondRaw,
            });
        },

        onChange() {
            if (!this.files.list.length) return;
            const firstImageIndex = this.files.list.findIndex((media) => !!media && !this.isVideo(media));
            if (firstImageIndex > -1) {
                this.files.list = [
                    this.files.list[firstImageIndex],
                    ...this.files.list.filter((value, i) => Boolean(value) && i !== firstImageIndex),
                ];
            } else {
                this.files.list = [null, ...this.files.list.filter(Boolean)];
            }
        },
    },
};
</script>

<style scoped lang="scss">
.primary-photo-wrapper {
    width: 100%;
    height: 100%;
}

.gallery-item {
    &.primary-item {
        outline: 2px solid #cccccc;
        outline-offset: 2px;
    }
}

.ghost {
    &-main {
        width: 100% !important;
        height: 100% !important;
    }

    &-medium {
        margin-bottom: 8px;
        width: 100% !important;
    }

    &-small {
        width: 65px !important;
        height: 50px !important;
    }
}

.img-action {
    min-height: 160px;

    &--block {
        min-height: 160px;
        width: 100%;
        position: relative;

        .v-icon {
            position: absolute;
            pointer-events: none;
            right: 10px;
            bottom: 10px;
        }
    }
}
</style>