<template>
    <v-text-field
        v-bind="$attrs"
        :value="value"
        :aria-label="ariaLabel"
        type="number"
        @input="onInput"
    >
        <template
            v-if="customPrependIcon"
            slot="prepend-inner"
        >
            <img
                style="width: 27px; height: 22px"
                :src="customIcon"
                alt=""
            >
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: 'AppNumberField',

    props: {
        value: {
            type: [Number, String],
            default: null,
        },

        ariaLabel: {
            type: String,
            default: null,
        },

        integer: {
            type: Boolean,
            default: false,
        },

        string: {
            type: Boolean,
            default: false,
        },

        customPrependIcon: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            customIcon: this.customPrependIcon ? require(`@/assets/icons/${this.customPrependIcon}.svg`) : null,
        };
    },

    methods: {
        onInput(ev) {
            if (!ev) {
                this.$emit('input', null);
                return;
            }

            if (this.string) {
                this.$emit('input', ev.replaceAll(/[^0-9.]/g, ''));
                return;
            }

            if (this.integer) {
                this.$emit('input', Number.parseInt(ev.replace('.', '')));
            } else {
                this.$emit('input', Number.parseFloat(ev));
            }
        },
    },
};
</script>

<style scoped></style>