<template>
    <div
        class="d-flex mt-10 justify-space-between"
    >
        <div><slot /></div>

        <div v-if="!isDeleted">
            <v-btn
                text
                class="mr-3"
                @click="$emit('cancel')"
            >
                cancel
            </v-btn>

            <v-btn
                color="primary"
                @click="$emit('publish')"
            >
                {{ submitLabel }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { DELETED } from '@/utils/filters';

export default {
    name: 'AdminDashboardActions',

    props: {
        submitLabel: {
            default: 'publish',
            type: String,
        },
        assetStatus: {
            type: String,
        },
    },

    computed: {
        isDeleted() {
            return this.assetStatus === DELETED;
        },
    },
};
</script>

<style scoped></style>