import { EVENT_BUS_EVENTS, EventBus } from '@/event-bus';

export const assetMixin = {
    created() {
        EventBus.$on(EVENT_BUS_EVENTS.SET_ASSET_DATA, this.setConfig);

        this.$once('hook:beforeDestroy', () => {
            EventBus.$off(EVENT_BUS_EVENTS.SET_ASSET_DATA, this.setConfig);
        });
    },
};
