import { validators } from '@/utils/validators';

export const RESIDENTIAL_FEATURES_FORM = [
    [
        [
            {
                label: 'Property type*',
                cols: 6,
                type: 'select',
                single: true,
                rules: [(v) => validators.selectRequired(v)],
                chips: false,
                key: 'propertyType',
            },
            {
                label: 'Broker Co-Op',
                type: 'select',
                cols: 3,
                single: true,
                chips: false,
                key: 'brokerCoOp',
            },
        ],
        [
            {
                label: 'Property subtype(s)',
                cols: 6,
                type: 'select',
                rules: [(v) => validators.maxLength(v, 2)],
                key: 'propertySubType',
            },
            {
                label: 'Terms Accepted',
                type: 'select',
                cols: 6,
                key: 'termsAccepted',
            },
        ],
        [
            {
                label: 'Beds*',
                cols: 3,
                type: 'number',
                integer: true,
                rules: [(v) => validators.required(v)],
                key: 'beds',
                prependIcon: 'mdi-bed',
            },
            {
                label: 'Baths*',
                cols: 3,
                type: 'number',
                integer: true,
                rules: [(v) => validators.required(v)],
                key: 'baths',
                customPrependIcon: 'bath',
            },
            {
                label: 'Occupied By',
                type: 'select',
                cols: 6,
                key: 'occupiedBy',
            },
        ],
        [
            {
                label: 'Year Built*',
                cols: 3,
                type: 'number',
                integer: true,
                rules: [(v) => validators.required(v), (v) => validators.inRange(v, 1000, 2100)],
                key: 'yearBuilt',
                prependIcon: 'mdi-calendar-check',
            },
            {
                label: 'Year Renovated',
                cols: 3,
                type: 'number',
                integer: true,
                rules: [(v) => validators.inRange(v, 1000, 2100)],
                key: 'yearRenovated',
                prependIcon: 'mdi-hammer',
            },
            {
                label: 'Ownership',
                type: 'select',
                cols: 6,
                key: 'ownership',
            },
        ],
        [
            {
                label: 'Square Footage*',
                cols: 3,
                type: 'number',
                integer: true,
                rules: [(v) => validators.required(v)],
                key: 'squareFootage',
                prependIcon: 'mdi-ruler',
            },
            {
                label: 'Possesion',
                type: 'select',
                offset: 3,
                cols: 6,
                key: 'possession',
            },
        ],
        [
            {
                label: 'Lot Size',
                cols: 4,
                type: 'number',
                colClass: 'pr-1',
                integer: true,
                key: 'lot',
                prependIcon: 'mdi-ruler',
            },
            {
                type: 'select',
                single: true,
                cols: 2,
                colClass: 'pl-0',
                chips: false,
                key: 'lotUnit',
            },
            {
                label: 'Home Warranty',
                cols: 3,
                type: 'select',
                single: true,
                chips: false,
                key: 'homeWarranty',
            },
            {
                label: 'Lot Dimensions',
                cols: 6,
                type: 'text',
                integer: true,
                key: 'lotDimentions',
                prependIcon: 'mdi-ruler-square',
            },
        ],
    ],
    [
        [
            {
                label: 'Style',
                cols: 6,
                type: 'select',
                key: 'style',
            },
            {
                label: 'Cooling',
                type: 'select',
                cols: 6,
                key: 'cooling',
            },
        ],
        [
            {
                label: 'Stories',
                cols: 6,
                type: 'select',
                key: 'stories',
            },
            {
                label: 'Heating',
                cols: 6,
                type: 'select',
                key: 'heating',
            },
        ],
        [
            {
                label: 'Garage/Spaces',
                cols: 3,
                type: 'number',
                integer: true,
                prependIcon: 'mdi-garage',
                key: 'garageSpaces',
            },
            {
                label: 'Electric',
                type: 'select',
                offset: 3,
                cols: 6,
                key: 'electric',
            },
        ],
        [
            {
                label: 'Basement',
                cols: 3,
                single: true,
                type: 'select',
                chips: false,
                key: 'basement',
            },
            {
                label: 'Gas',
                cols: 6,
                offset: 3,
                type: 'select',
                key: 'gas',
            },
        ],
        [
            {
                label: 'Recent Updates',
                cols: 6,
                type: 'select',
                key: 'recentUpdates',
            },
            {
                label: 'Water',
                cols: 6,
                type: 'select',
                key: 'water',
            },
        ],
        [
            {
                label: 'Security Features',
                cols: 6,
                type: 'select',
                key: 'securityFeatures',
            },
            {
                label: 'Sewer',
                cols: 6,
                type: 'select',
                key: 'sewer',
            },
        ],
    ],
    [
        [
            {
                label: 'Property Taxes',
                cols: 3,
                type: 'number',
                prefix: '$',
                key: 'propertyTaxes',
            },
            {
                label: 'Special Assessment Taxes',
                cols: 3,
                type: 'number',
                prefix: '$',
                key: 'specialAssessmentTaxes',
            },
            {
                label: 'County',
                type: 'text',
                cols: 6,
                key: 'county',
            },
        ],
        [
            {
                label: 'Tax Exemptions',
                cols: 6,
                type: 'select',
                key: 'taxExemptions',
            },
            {
                label: 'Zoning',
                cols: 6,
                type: 'select',
                key: 'zoning',
            },
        ],
        [
            {
                label: 'HOA Fee(s)',
                cols: 4,
                colClass: 'pr-0',
                type: 'number',
                prefix: '$',
                key: 'hoaFee',
            },
            {
                cols: 2,
                single: true,
                type: 'select',
                key: 'hoaUnit',
                ariaLabel: 'HOA Fee Paid',
                chips: false,
            },
            {
                label: 'Subdivision',
                type: 'text',
                cols: 6,
                key: 'subdivision',
            },
        ],
        [
            {
                label: 'HOA Amenities',
                cols: 6,
                type: 'select',
                key: 'hoaAmenities',
            },
            {
                label: 'School District',
                cols: 6,
                type: 'text',
                key: 'schoolDistrict',
            },
        ],
    ],
];

export const COMMERCIAL_FEATURES_FORM = [
    [
        [
            {
                label: 'Property type*',
                cols: 6,
                type: 'select',
                single: true,
                rules: [(v) => validators.selectRequired(v)],
                key: 'propertyType',
                chips: false,
            },
            {
                label: 'Broker Co-Op',
                type: 'select',
                cols: 3,
                single: true,
                chips: false,
                key: 'brokerCoOp',
            },
        ],
        [
            {
                label: 'Property subtype(s)',
                cols: 6,
                type: 'select',
                rules: [(v) => validators.maxLength(v, 2)],
                key: 'propertySubType',
            },
            {
                label: 'Terms Accepted',
                type: 'select',
                cols: 6,
                key: 'termsAccepted',
            },
        ],
        [
            {
                label: 'Year Built*',
                cols: 3,
                type: 'number',
                integer: true,
                rules: [(v) => validators.required(v), (v) => validators.inRange(v, 1000, 2100)],
                key: 'yearBuilt',
                prependIcon: 'mdi-calendar-check',
            },
            {
                label: 'Year Renovated',
                cols: 3,
                type: 'number',
                integer: true,
                rules: [(v) => validators.inRange(v, 1000, 2100)],
                key: 'yearRenovated',
                prependIcon: 'mdi-hammer',
            },
            {
                label: 'Lease Type',
                type: 'select',
                cols: 6,
                key: 'leaseType',
            },
        ],
        [
            {
                label: 'Square Footage*',
                cols: 3,
                type: 'number',
                integer: true,
                rules: [(v) => validators.required(v)],
                key: 'squareFootage',
                prependIcon: 'mdi-ruler',
            },
            {
                label: 'Lease Expiration',
                cols: 3,
                offset: 3,
                type: 'date',
                key: 'leaseExpiration',
                prependIcon: 'mdi-calendar',
            },
        ],
        [
            {
                label: 'Lot Size',
                cols: 4,
                type: 'number',
                colClass: 'pr-1',
                integer: true,
                key: 'lot',
                prependIcon: 'mdi-ruler',
            },
            {
                type: 'select',
                single: true,
                cols: 2,
                colClass: 'pl-0',
                chips: false,
                key: 'lotUnit',
            },
            {
                label: 'Occupied By',
                type: 'select',
                cols: 6,
                key: 'occupiedBy',
            },
        ],
        [
            {
                label: 'Lot Dimensions',
                cols: 6,
                type: 'text',
                integer: true,
                key: 'lotDimentions',
                prependIcon: 'mdi-ruler-square',
            },
            {
                label: 'Ownership',
                type: 'select',
                cols: 6,
                key: 'ownership',
            },
        ],
        [
            {
                label: 'Possession',
                type: 'select',
                offset: 6,
                cols: 6,
                key: 'possession',
            },
        ],
    ],
    [
        [
            {
                label: 'Total Buildings',
                cols: 3,
                type: 'number',
                integer: true,
                key: 'totalBuildings',
                prependIcon: 'mdi-domain',
            },
            {
                label: 'Total Units',
                cols: 3,
                type: 'number',
                integer: true,
                key: 'totalUnits',
                prependIcon: 'mdi-puzzle-outline',
            },
            {
                label: 'Net Operating Income',
                cols: 3,
                type: 'number',
                prefix: '$',
                key: 'netOperatingIncome',
            },
            {
                label: 'Occupancy',
                cols: 3,
                type: 'number',
                suffix: '%',
                key: 'occupancy',
            },
        ],
        [
            {
                label: 'Total Floors',
                cols: 3,
                type: 'number',
                integer: true,
                key: 'totalFloors',
                prependIcon: 'mdi-stairs-up',
            },
            {
                label: 'Garage/Spaces',
                cols: 3,
                type: 'number',
                integer: true,
                prependIcon: 'mdi-garage',
                key: 'garageSpaces',
            },
            {
                label: 'Cap Rate %',
                cols: 3,
                type: 'number',
                suffix: '%',
                key: 'capRate',
            },
            {
                label: 'Average Monthly Rent/Unit',
                cols: 3,
                type: 'number',
                prefix: '$',
                key: 'averageMonthlyRentUnit',
            },
        ],
        [
            {
                label: 'Basement',
                cols: 3,
                single: true,
                chips: false,
                type: 'select',
                key: 'basement',
            },
            {
                label: 'Tenant Pays',
                cols: 6,
                offset: 3,
                type: 'select',
                key: 'tenantPays',
            },
        ],
        [
            {
                label: 'Recent Updates',
                cols: 6,
                type: 'select',
                key: 'recentUpdates',
            },
            {
                label: 'Inclusions',
                cols: 6,
                type: 'select',
                key: 'inclusions',
            },
        ],
        [
            {
                label: 'Security Features',
                cols: 6,
                type: 'select',
                key: 'securityFeatures',
            },
            {
                label: 'Building Class',
                cols: 6,
                type: 'select',
                key: 'buildingClass',
            },
        ],
    ],
    [
        [
            {
                label: 'Cooling',
                cols: 6,
                type: 'select',
                key: 'cooling',
            },
            {
                label: 'County',
                type: 'text',
                cols: 6,
                key: 'county',
            },
        ],
        [
            {
                label: 'Heating',
                cols: 6,
                type: 'select',
                key: 'heating',
            },
            {
                label: 'Zoning',
                cols: 6,
                type: 'select',
                key: 'zoning',
            },
        ],
        [
            {
                label: 'Electric',
                cols: 6,
                type: 'select',
                key: 'electric',
            },
            {
                label: 'Subdivision',
                type: 'text',
                cols: 6,
                key: 'subdivision',
            },
        ],
        [
            {
                label: 'Gas',
                cols: 6,
                type: 'select',
                key: 'gas',
            },
            {
                label: 'Property Taxes',
                cols: 3,
                type: 'number',
                prefix: '$',
                key: 'propertyTaxes',
            },
            {
                label: 'Special Assessment Taxes',
                cols: 3,
                type: 'number',
                prefix: '$',
                key: 'specialAssessmentTaxes',
            },
        ],
        [
            {
                label: 'Water',
                cols: 6,
                type: 'select',
                key: 'water',
            },
            {
                label: 'Tax Exemptions',
                cols: 6,
                type: 'select',
                key: 'taxExemptions',
            },
        ],
        [
            {
                label: 'Sewer',
                cols: 6,
                type: 'select',
                key: 'sewer',
            },
            {
                label: 'HOA Fee(s)',
                cols: 4,
                colClass: 'pr-0',
                type: 'number',
                prefix: '$',
                key: 'hoaFee',
            },
            {
                cols: 2,
                single: true,
                type: 'select',
                key: 'hoaUnit',
                ariaLabel: 'HOA Fee Paid',
                chips: false,
            },
        ],
        [
            {
                label: 'HOA Amenities',
                cols: 6,
                offset: 6,
                type: 'select',
                key: 'hoaAmenities',
            },
        ],
    ],
];

export const LAND_FEATURES_FORM = [
    [
        [
            {
                label: 'Property type*',
                cols: 6,
                type: 'select',
                single: true,
                rules: [(v) => validators.selectRequired(v)],
                key: 'propertyType',
            },
            {
                label: 'Broker Co-Op',
                type: 'select',
                cols: 3,
                single: true,
                chips: false,
                key: 'brokerCoOp',
            },
        ],
        [
            {
                label: 'Property subtype(s)',
                cols: 6,
                type: 'select',
                rules: [(v) => validators.maxLength(v, 2)],
                key: 'propertySubType',
            },
            {
                label: 'Terms Accepted',
                cols: 6,
                type: 'select',
                key: 'termsAccepted',
            },
        ],
        [
            {
                label: 'Total Acres*',
                cols: 3,
                type: 'number',
                rules: [(v) => validators.required(v)],
                key: 'totalAcres',
                suffix: '+/-',
                prependIcon: 'mdi-ruler-square',
            },
            {
                label: 'Ownership',
                type: 'select',
                cols: 6,
                offset: 3,
                key: 'ownership',
            },
        ],
        [
            {
                label: 'Dryland Acres',
                cols: 3,
                type: 'number',
                key: 'drylandAcres',
                suffix: '+/-',
                prependIcon: 'mdi-cactus',
            },
            {
                label: 'Irrigated Acres',
                cols: 3,
                type: 'number',
                key: 'irrigatedAcres',
                suffix: '+/-',
                prependIcon: 'mdi-sprinkler-variant',
            },
            {
                label: 'Possession',
                type: 'select',
                cols: 6,
                key: 'possession',
            },
        ],
        [
            {
                label: 'Grass Acres',
                cols: 3,
                type: 'number',
                key: 'grassAcres',
                suffix: '+/-',
                prependIcon: 'mdi-grass',
            },
            {
                label: 'Pasture/Fenced Acres',
                cols: 3,
                type: 'number',
                key: 'pastureFencedAcres',
                suffix: '+/-',
                customPrependIcon: 'fence',
            },
            {
                label: 'Lease Expiration',
                cols: 3,
                type: 'date',
                key: 'leaseExpiration',
                prependIcon: 'mdi-calendar',
            },
        ],
        [
            {
                label: 'CRP Acres',
                cols: 3,
                type: 'number',
                key: 'crpAcres',
                suffix: '+/-',
                prependIcon: 'mdi-corn-off',
            },
            {
                label: 'Timber Acres',
                cols: 3,
                type: 'number',
                key: 'timberAcres',
                suffix: '+/-',
                customPrependIcon: 'wood',
            },
            {
                label: 'Mineral Rights',
                type: 'select',
                cols: 6,
                key: 'mineralRights',
            },
        ],
        [
            {
                label: 'Lot Acres',
                cols: 3,
                type: 'number',
                key: 'lotAcres',
                suffix: '+/-',
                prependIcon: 'mdi-ruler-square',
            },
            {
                label: 'Balance/Other Acres',
                cols: 3,
                type: 'number',
                key: 'balanceOtherAcres',
                suffix: '+/-',
                prependIcon: 'mdi-ruler',
            },
            {
                label: 'Easements',
                type: 'select',
                cols: 6,
                key: 'easements',
            },
        ],
        [
            {
                label: 'Survey',
                type: 'select',
                cols: 6,
                offset: 6,
                key: 'survey',
            },
        ],
    ],
    [
        [
            {
                label: 'Water',
                type: 'select',
                cols: 12,
                key: 'water',
            },
        ],
        [
            {
                label: 'Utilities',
                type: 'select',
                cols: 12,
                key: 'utilities',
            },
        ],
        [
            {
                label: 'Sewer',
                type: 'select',
                cols: 12,
                key: 'sewer',
            },
        ],
        [
            {
                label: 'Ponds',
                cols: 6,
                type: 'number',
                integer: true,
                key: 'ponds',
                prependIcon: 'mdi-waves',
            },
        ],
        [
            {
                label: 'Wells',
                cols: 6,
                type: 'number',
                integer: true,
                key: 'wells',
                prependIcon: 'mdi-water-well',
            },
        ],
    ],
    [
        [
            {
                label: 'Improvements',
                type: 'select',
                cols: 6,
                key: 'improvements',
            },
            {
                label: 'Road Frontage',
                type: 'select',
                cols: 6,
                key: 'roadFrontage',
            },
        ],
        [
            {
                label: 'Outbuildings',
                type: 'select',
                cols: 6,
                key: 'outbuildings',
            },
            {
                label: 'Topography',
                type: 'select',
                cols: 6,
                key: 'topography',
            },
        ],
        [
            {
                label: 'Other Features',
                type: 'select',
                cols: 6,
                key: 'otherFeatures',
            },
            {
                label: 'Wild Life',
                type: 'select',
                cols: 6,
                key: 'wildlife',
            },
        ],
        [
            {
                label: 'Fence',
                type: 'select',
                cols: 6,
                key: 'fence',
            },
            {
                label: 'Fish',
                type: 'select',
                cols: 6,
                key: 'fish',
            },
        ],
        [
            {
                label: 'Irrigation System',
                type: 'select',
                cols: 6,
                key: 'irrigationSystem',
            },
            {
                label: 'Recreation',
                type: 'select',
                cols: 6,
                key: 'recreation',
            },
        ],
        [
            {
                label: 'Livestock Carrying Capacity',
                cols: 3,
                type: 'number',
                integer: true,
                key: 'livestockCarryingCapacity',
            },
        ],
        [
            {
                cols: 6,
                type: 'crop-retire',
            },
        ],
    ],
    [
        [
            {
                label: 'County',
                type: 'text',
                cols: 6,
                key: 'county',
            },
            {
                label: 'Property Taxes',
                cols: 3,
                type: 'number',
                prefix: '$',
                key: 'propertyTaxes',
            },
            {
                label: 'Special Assessment Taxes',
                cols: 3,
                type: 'number',
                prefix: '$',
                key: 'specialAssessmentTaxes',
            },
        ],
        [
            {
                label: 'Zoning',
                type: 'select',
                cols: 6,
                key: 'zoning',
            },
            {
                label: 'Tax Exemptions',
                type: 'select',
                cols: 6,
                key: 'taxExemptions',
            },
        ],
        [
            {
                label: 'Subdivision',
                type: 'text',
                cols: 6,
                key: 'subdivision',
            },
        ],
    ],
];
