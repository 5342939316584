export const RESIDENTIAL_EXTERIOR_FORM = [
    [
        [
            {
                label: 'Construction',
                cols: 6,
                type: 'select',
                key: 'construction',
            },
            {
                label: 'Garage/Parking',
                type: 'select',
                cols: 6,
                key: 'garageParking',
            },
        ],
        [
            {
                label: 'Exterior Features',
                type: 'select',
                cols: 6,
                key: 'exteriorFeatures',
            },
            {
                label: 'Garage Features',
                type: 'select',
                cols: 6,
                key: 'garageFeatures',
            },
        ],
        [
            {
                label: 'Roof',
                cols: 6,
                type: 'select',
                key: 'roof',
            },
            {
                label: 'Outbuildings',
                type: 'select',
                cols: 6,
                key: 'outbuildings',
            },
        ],
        [
            {
                label: 'Foundation',
                cols: 6,
                type: 'select',
                key: 'foundation',
            },
            {
                label: 'Location Features',
                type: 'select',
                cols: 6,
                key: 'locationFeatures',
            },
        ],
        [
            {
                label: 'Fence',
                type: 'select',
                cols: 6,
                key: 'fence',
            },
            {
                label: 'Road Frontage',
                type: 'select',
                cols: 6,
                key: 'roadFrontage',
            },
        ],
        [
            {
                label: 'Pool',
                type: 'select',
                cols: 6,
                key: 'pool',
            },
            {
                label: 'Property Faces',
                cols: 6,
                type: 'select',
                key: 'propertyFaces',
            },
        ],
    ],
];

export const COMMERCIAL_EXTERIOR_FORM = [
    [
        [
            {
                label: 'Construction',
                cols: 6,
                type: 'select',
                key: 'construction',
            },
            {
                label: 'Garage/Parking',
                type: 'select',
                cols: 6,
                key: 'garageParking',
            },
        ],
        [
            {
                label: 'Exterior Features',
                cols: 6,
                type: 'select',
                key: 'exteriorFeatures',
            },
            {
                label: 'Outbuildings',
                type: 'select',
                cols: 6,
                key: 'outbuildings',
            },
        ],
        [
            {
                label: 'Roof',
                cols: 6,
                type: 'select',
                key: 'roof',
            },
            {
                label: 'Location Features',
                type: 'select',
                cols: 6,
                key: 'locationFeatures',
            },
        ],
        [
            {
                label: 'Foundation',
                cols: 6,
                type: 'select',
                key: 'foundation',
            },
            {
                label: 'Road Frontage',
                type: 'select',
                cols: 6,
                key: 'roadFrontage',
            },
        ],
        [
            {
                label: 'Fence',
                cols: 6,
                type: 'select',
                key: 'fence',
            },
            {
                label: 'Property Faces',
                cols: 6,
                type: 'select',
                key: 'propertyFaces',
            },
        ],
        [
            {
                label: 'Pool',
                type: 'select',
                cols: 6,
                key: 'pool',
            },
        ],
    ],
];
