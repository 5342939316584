<template>
    <div class="mb-10">
        <h3>General</h3>

        <div class="asset-section--content">
            <v-row v-if="isBasic">
                <v-col cols="12">
                    <v-text-field
                        v-model="formData.title"
                        :rules="rules"
                        label="Asset title"
                    />
                </v-col>
            </v-row>
            <v-row v-if="!isBasic">
                <v-col cols="4">
                    <v-text-field
                        v-model="formData.address"
                        :rules="rules"
                        label="Property address*"
                    />
                </v-col>

                <v-col cols="3">
                    <v-text-field
                        v-model="formData.city"
                        :rules="rules"
                        label="City*"
                    />
                </v-col>

                <v-col cols="3">
                    <v-select
                        v-model="formData.state"
                        :rules="rules"
                        label="State*"
                        :items="states"
                    />
                </v-col>

                <v-col cols="2">
                    <app-number-field
                        v-model="formData.zip"
                        string
                        :rules="isLand ? [] : rules"
                        :label="isLand ? 'Zip' : 'Zip*'"
                    />
                </v-col>
            </v-row>

            <v-row v-if="!isBasic">
                <v-col cols="12">
                    <v-text-field
                        v-model="formData.openHouseDates"
                        label="Open House Dates"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <p class="mb-1 subtitle">
                        About The Property
                    </p>
                    <ckeditor
                        v-model="formData.description"
                        :editor="editor"
                        :config="{}"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { states } from '@/utils/constants';
import { validators } from '@/utils/validators';
import { assetMixin } from '@/mixins/assetMixin';
import AppNumberField from '@/components/App/AppNumberField';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'AssetGeneralForm',

    components: {
        AppNumberField,
        ckeditor: CKEditor.component,
    },

    mixins: [assetMixin],

    props: {
        title: {
            type: String,
            default: null,
        },
        address: {
            type: Object,
            default: () => {
                return {
                    address: null,
                    city: null,
                    state: null,
                    zip: null,
                };
            },
        },
        openHouseDates: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            editor: ClassicEditor,
            states,
            rules: [(v) => validators.required(v)],
            formData: {
                title: this.title,
                address: this.address.address,
                city: this.address.city,
                state: this.address.state,
                zip: this.address.zip,
                openHouseDates: this.openHouseDates,
                description: this.description,
            },
        };
    },

    computed: {
        isLand() {
            return this.$store.state.Asset.kind === 'Land';
        },
        isBasic() {
            return this.$store.state.Asset.kind === 'Basic';
        },
    },

    methods: {
        setConfig() {
            this.$store.commit('Asset/setGeneralInfo', this.formData);
        },
    },
};
</script>

<style scoped>
.subtitle {
    color: #6f6f6f;
    font-size: 12px;
}
</style>