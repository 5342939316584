<template>
    <div class="mb-10">
        <h3>Documents</h3>

        <div class="pa-0">
            <draggable :list="files">
                <div
                    v-for="(file, i) in files"
                    :key="i"
                    class="pa-5 d-flex justify-space-between align-center"
                >
                    <div class="d-flex align-center">
                        <v-icon
                            class="mr-2"
                            color="primary"
                            x-large
                        >
                            {{ file.icon ? file.icon : getFileIcon(file.type) }}
                        </v-icon>
                        <a
                            target="_blank"
                            :href="file.url || '#'"
                        >{{ file.name }}</a>
                    </div>

                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="removeFile(i)">
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </draggable>

            <app-dropzone
                class="placeholder-block py-10 my-2 d-flex justify-center align-center"
                @drop="onFileDrop"
            >
                <v-icon
                    x-large
                    class="mr-2"
                    color="primary"
                >
                    mdi-file-document
                </v-icon>

                <input
                    ref="fileInput"
                    type="file"
                    style="visibility: hidden; position: absolute; left: -1000px"
                    @input="onFileSelect"
                >
                <div class="d-flex flex-column">
                    <div>
                        Drag and drop,
                        <span
                            style="text-decoration: underline; color: #000; cursor: pointer;"
                            @click="$refs.fileInput.click()"
                        >browse files</span>
                    </div>

                    <span class="text-small">Maximum file size is: 200MB</span>
                    <span class="text-small">Maximum number of files is 100</span>
                </div>
            </app-dropzone>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { assetMixin } from '@/mixins/assetMixin';
import AppDropzone from '@/components/App/AppDropzone';
import { Storage } from 'aws-amplify';
import { Utils } from '@/utils';

export default {
    name: 'AssetDocuments',

    components: {
        AppDropzone,
        draggable,
    },

    mixins: [assetMixin],

    props: {
        documents: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            files: [],
        };
    },

    async created() {
        const requests = this.documents.map(async (file) => {
            const url = await Storage.get(Utils.extractFileKey(file.key), { level: 'public' });

            return {
                icon: this.getFileIcon(file.name),
                key: file.key,
                url,
                name: file.name,
            };
        });

        const res = await Promise.all(requests);

        this.files = [...res];
    },

    methods: {
        onFileDrop(ev) {
            this.files.push(...ev);
        },

        removeFile(i) {
            this.files.splice(i, 1);
        },

        getFileIcon(type) {
            return Utils.getFileIcon(type);
        },

        setConfig() {
            this.$store.commit('Asset/setDocuments', this.files);
        },

        onFileSelect(ev) {
            if (this.files.length >= 100) {
                this.$store.commit('snackbar', {
                    text: 'Maximum number of files is 100',
                    color: 'error',
                    isOpened: true,
                });

                return;
            }

            const file = ev.target.files[0];

            if (file) {
                if (file.size > 20000000) {
                    this.$store.commit('snackbar', {
                        text: 'Maximum file size is: 200MB',
                        color: 'error',
                        isOpened: true,
                    });

                    return;
                }

                this.files.push(file);
            }
        },
    },
};
</script>

<style scoped lang="scss"></style>