<template>
    <div
        @drop="dropHandler"
        @dragover.prevent
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'AppDropzone',

    methods: {
        dropHandler(ev) {
            ev.preventDefault();

            const files = [];

            if (ev.dataTransfer.items) {
                for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        const file = ev.dataTransfer.items[i].getAsFile();

                        if (this.isOkSize(file)) {
                            files.push(file);
                        }
                    }
                }
            } else {
                for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                    const file = ev.dataTransfer.files[i];

                    if (this.isOkSize(file)) {
                        files.push(file);
                    }
                }
            }

            this.$emit('drop', files);
        },

        isOkSize(file) {
            if (file.size > 20000000) {
                this.$store.commit('snackbar', {
                    text: 'Maximum file size is: 200MB',
                    color: 'error',
                    isOpened: true,
                });

                return false;
            }

            return true;
        },
    },
};
</script>

<style scoped></style>