<template>
    <div class="media-wrapper">
        <v-img
            :src="src"
            :aspect-ratio="4/3"
            v-bind="$attrs"
        />

        <v-icon
            v-if="isVideo"
            color="mainRed"
            large
        >
            mdi-youtube
        </v-icon>
    </div>
</template>

<script>
export default {
    name: 'AssetGalleryMedia',

    inheritAttrs: false,

    props: {
        isVideo: {
            type: Boolean,
        },
        src: {
            type: String,
        },
    },
};
</script>

<style scoped lang="scss">
.media-wrapper {
    position: relative;
    cursor: pointer;

    .v-icon {
        position: absolute;
        top: calc(50% - 18px);
        left: calc(50% - 18px);
        pointer-events: none;
    }
}
</style>