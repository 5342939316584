<template>
    <div class="asset-section">
        <h3>Interior Features</h3>

        <div
            v-for="(section, sectionIndex) in form"
            :key="sectionIndex"
            class="asset-section--content"
            :class="sectionIndex !== 0 ? 'mt-10' : ''"
        >
            <template v-if="sectionIndex === 0 && $store.state.Asset.kind === 'Residential'">
                <v-row>
                    <v-col
                        cols="6"
                        class="py-2"
                    >
                        <v-row no-gutters>
                            <v-col cols="3" />
                            <v-col
                                class="pb-2 text-center"
                                cols="3"
                            >
                                SQ FT
                            </v-col>
                            <v-col
                                class="pb-2 text-center"
                                cols="3"
                            >
                                Bedrooms
                            </v-col>
                            <v-col
                                class="pb-2 text-center"
                                cols="3"
                            >
                                Bathrooms
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col
                                class="pa-2"
                                cols="3"
                            >
                                Upper Floor
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.sqftUpperFloor"
                                    integer
                                    aria-label="SQ FT Upper Floor"
                                    dense
                                />
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.bedroomsUpperFloor"
                                    integer
                                    aria-label="Bedrooms Upper Floor"
                                    dense
                                />
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.bathroomsUpperFloor"
                                    integer
                                    aria-label="Bathrooms Upper Floor"
                                    dense
                                />
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col
                                class="pa-2"
                                cols="3"
                            >
                                Main Floor
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.sqftMainFloor"
                                    integer
                                    aria-label="SQ FT Main Floor"
                                    dense
                                />
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.bedroomsMainFloor"
                                    integer
                                    aria-label="Bedrooms Main Floor"
                                    dense
                                />
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.bathroomsMainFloor"
                                    integer
                                    aria-label="Bathrooms Main Floor"
                                    dense
                                />
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col
                                class="pa-2"
                                cols="3"
                            >
                                Basement
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.sqftBasement"
                                    integer
                                    aria-label="SQ FT Basement"
                                    dense
                                />
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.bedroomsBasement"
                                    integer
                                    aria-label="Bedrooms Basement"
                                    dense
                                />
                            </v-col>
                            <v-col
                                cols="3"
                                class="px-5 bg-white"
                            >
                                <app-number-field
                                    v-model="formData.bathroomsBasement"
                                    integer
                                    aria-label="Bathrooms Basement"
                                    dense
                                />
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="6">
                        <v-row
                            v-for="(row, rowIndex) in section"
                            :key="rowIndex"
                        >
                            <v-col
                                v-for="(col, colIndex) in row"
                                :key="colIndex"
                                cols="12"
                            >
                                <app-select
                                    v-model="formData[col.key]"
                                    :multiple="!col.single"
                                    :items="options[col.optionsKey || col.key]"
                                    :label="col.label"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </template>

            <template v-else>
                <v-row
                    v-for="(row, j) in section"
                    :key="j"
                >
                    <v-col
                        v-for="(col, k) in row"
                        :key="k"
                        :cols="col.cols"
                        :class="col.colClass"
                        :offset="col.offset || 0"
                    >
                        <app-select
                            v-if="col.type === 'select'"
                            v-model="formData[col.key]"
                            :aria-label="col.ariaLabel"
                            :label="col.label"
                            :chips="col.chips"
                            :boolean="col.boolean"
                            :multiple="!col.single"
                            :items="options[col.optionsKey || col.key]"
                            :rules="col.rules || []"
                        />

                        <app-number-field
                            v-else-if="col.type === 'number'"
                            v-model="formData[col.key]"
                            :prepend-inner-icon="col.prependIcon"
                            :custom-prepend-icon="col.customPrependIcon"
                            :label="col.label"
                            :prefix="col.prefix"
                            :suffix="col.suffix"
                            :integer="col.integer"
                            :rules="col.rules || []"
                        />
                    </v-col>
                </v-row>
            </template>
        </div>
    </div>
</template>

<script>
import { states } from '@/utils/constants';
import { assetMixin } from '@/mixins/assetMixin';
import AppSelect from '@/components/App/AppSelect';
import AppNumberField from '@/components/App/AppNumberField';
import { COMMERCIAL_INTERIOR_FORM, RESIDENTIAL_INTERIOR_FORM } from '@/constants/interiorForm';
import { Utils } from '@/utils';

export default {
    name: 'AssetInteriorForm',

    components: { AppNumberField, AppSelect },

    mixins: [assetMixin],

    props: {
        interior: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            forms: {
                Residential: RESIDENTIAL_INTERIOR_FORM,
                Commercial: COMMERCIAL_INTERIOR_FORM,
            },

            commercialTotalField: [
                {
                    key: 'totalBuildings',
                    label: 'Total Buildings',
                },
                {
                    key: 'totalUnits',
                    label: 'Total Units',
                },
                {
                    key: 'totalFloors',
                    label: 'Total Floors',
                },
                {
                    key: 'totalRooms',
                    label: 'Total Rooms',
                },
                {
                    key: 'totalBedroons',
                    label: 'Total Bedrooms',
                },
                {
                    key: 'totalBathrooms',
                    label: 'Total Bathrooms',
                },
                {
                    key: 'totalPublicRestrooms',
                    label: 'Total Public Restrooms',
                },
            ],

            formData: {
                sqftMainFloor: null,
                sqftUpperFloor: null,
                sqftBasement: null,
                bedroomsMainFloor: null,
                bedroomsUpperFloor: null,
                bedroomsBasement: null,
                bathroomsMainFloor: null,
                bathroomsUpperFloor: null,
                bathroomsBasement: null,
                kitchenFeatures: [],
                appliances: [],
                flooring: [],
                windows: [],
                bedroomFeatures: [],
                bathroomFeatures: [],
                masterBedroomFeatures: [],
                basementFeatures: [],
                otherRooms: [],
                otherFeatures: [],
                fireplace: null,
                fireplaceUnit: [],
                handicapAmenities: [],
                totalRooms: null,
                totalBedrooms: null,
                totalBathrooms: null,
                totalPublicRestrooms: null,
                ceilingHeight: null,
                interiorFeatures: [],
            },
            states,
        };
    },

    computed: {
        form() {
            return this.forms[this.$store.state.Asset.kind];
        },

        options() {
            return this.$store.getters['Asset/options'];
        },
    },

    created() {
        this.formData = {
            ...this.formData,
            ...this.interior,
        };
    },

    methods: {
        setConfig() {
            const res = {
                ...this.formData,
            };

            const keys = Utils.extractKeysFromForm(this.form);

            if (this.$store.state.Asset.kind === 'Residential') {
                keys.push(
                    'sqftMainFloor',
                    'sqftUpperFloor',
                    'sqftBasement',
                    'bedroomsMainFloor',
                    'bedroomsUpperFloor',
                    'bedroomsBasement',
                    'bathroomsMainFloor',
                    'bathroomsUpperFloor',
                    'bathroomsBasement',
                );
            }

            const filtered = Utils.filterObject(res, keys);

            this.$store.commit('Asset/setInterior', filtered);
        },
    },
};
</script>

<style scoped></style>