export const validators = {
    required(v, fieldName = 'Field') {
        return !!v || v === 0 || fieldName + ' is required';
    },

    selectRequired(v, fieldName = 'Field') {
        return (!!v && !!v.length) || fieldName + ' is required';
    },

    maxLength(v, max, customMessage) {
        const message = customMessage ? customMessage : 'Maximum number of options is ' + max;
        return !v || (!!v && v.length <= max) || message;
    },

    inRange(v, min, max) {
        if (v && v < min) {
            return 'Minimum allowed value is ' + min;
        }

        if (v && v > max) {
            return 'Maximum allowed value is ' + max;
        }

        return true;
    },
};
