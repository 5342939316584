<template>
    <div class="asset-section">
        <h4 class="asset-section--title">
            Exterior Features
        </h4>

        <div
            v-for="(section, i) in form"
            :key="i"
            class="asset-section--content"
            :class="i !== 0 ? 'mt-10' : ''"
        >
            <v-row
                v-for="(row, rowIndex) in section"
                :key="rowIndex"
            >
                <v-col
                    v-for="(col, colIndex) in row"
                    :key="colIndex"
                    :cols="col.cols"
                    :class="col.colClass"
                    :offset="col.offset || 0"
                >
                    <app-select
                        v-model="formData[col.key]"
                        :label="col.label"
                        :chips="col.chips"
                        :multiple="!col.single"
                        :items="options[col.optionsKey || col.key]"
                        :rules="col.rules || []"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { states } from '@/utils/constants';
import { assetMixin } from '@/mixins/assetMixin';
import AppSelect from '@/components/App/AppSelect';
import { COMMERCIAL_EXTERIOR_FORM, RESIDENTIAL_EXTERIOR_FORM } from '@/constants/exteriorForm';
import { Utils } from '@/utils';

export default {
    name: 'AssetExteriorForm',

    components: { AppSelect },

    mixins: [assetMixin],

    props: {
        exterior: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            forms: {
                Residential: RESIDENTIAL_EXTERIOR_FORM,
                Commercial: COMMERCIAL_EXTERIOR_FORM,
            },

            formData: {
                construction: [],
                exteriorFeatures: [],
                roof: [],
                foundation: [],
                fence: [],
                pool: [],
                garageParking: [],
                garageFeatures: [],
                outbuildings: [],
                locationFeatures: [],
                roadFrontage: [],
                propertyFaces: [],
            },
            states,
        };
    },

    computed: {
        form() {
            return this.forms[this.$store.state.Asset.kind];
        },

        options() {
            return this.$store.getters['Asset/options'];
        },
    },

    created() {
        this.formData = {
            ...this.formData,
            ...this.exterior,
        };
    },

    methods: {
        setConfig() {
            const res = {
                ...this.formData,
            };

            const keys = Utils.extractKeysFromForm(this.form);

            const filtered = Utils.filterObject(res, keys);

            this.$store.commit('Asset/setExterior', filtered);
        },
    },
};
</script>

<style scoped></style>