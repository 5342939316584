<template>
    <div class="asset-section">
        <h4 class="asset-section--title">
            Property Highlights
        </h4>

        <div
            v-for="(section, i) in form"
            :key="i"
            class="asset-section--content"
            :class="i !== 0 ? 'mt-10' : ''"
        >
            <template v-if="i === 1 && $store.state.Asset.kind === 'Land'">
                <v-row>
                    <v-col
                        cols="6"
                    >
                        <v-row
                            v-for="(row, rowIndex) in section"
                            :key="rowIndex"
                        >
                            <v-col
                                v-for="(col, colIndex) in row"
                                :key="colIndex"
                                :cols="col.cols"
                            >
                                <app-select
                                    v-if="col.type === 'select'"
                                    v-model="formData[col.key]"
                                    :aria-label="col.ariaLabel"
                                    :label="col.label"
                                    :chips="col.chips"
                                    :multiple="!col.single"
                                    :items="options[col.optionsKey || col.key]"
                                    :rules="col.rules || []"
                                />

                                <app-number-field
                                    v-else
                                    v-model="formData[col.key]"
                                    :prepend-inner-icon="col.prependIcon"
                                    :custom-prepend-icon="col.customPrependIcon"
                                    :label="col.label"
                                    :integer="col.integer"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="formData.fsaInformation"
                                    rows="4"
                                    label="FSA Information"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="formData.cropYieldHistory"
                                    rows="4"
                                    label="Crop Yield History"
                                />
                            </v-col>
                            <v-col cols="12">
                                <app-number-field
                                    v-model="formData.soilProductivityRating"
                                    label="Soil Productivity Rating"
                                    integer
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </template>
            <v-row
                v-for="(row, rowIndex) in section"
                v-else
                :key="rowIndex"
            >
                <v-col
                    v-for="(col, colIndex) in row"
                    :key="colIndex"
                    :cols="col.cols"
                    :class="col.colClass"
                    :offset="col.offset || 0"
                >
                    <app-select
                        v-if="col.type === 'select'"
                        v-model="formData[col.key]"
                        :aria-label="col.ariaLabel"
                        :label="col.label"
                        :chips="col.chips"
                        :multiple="!col.single"
                        :items="options[col.optionsKey || col.key]"
                        :rules="col.rules || []"
                    />

                    <app-number-field
                        v-else-if="col.type === 'number'"
                        v-model="formData[col.key]"
                        :prepend-inner-icon="col.prependIcon"
                        :custom-prepend-icon="col.customPrependIcon"
                        :label="col.label"
                        :prefix="col.prefix"
                        :suffix="col.suffix"
                        :integer="col.integer"
                        :rules="col.rules || []"
                    />

                    <v-menu
                        v-else-if="col.type === 'date'"
                        v-model="dataMenu"
                        offset-y
                        :close-on-content-click="false"
                        left
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="formData[col.key]"
                                v-bind="attrs"
                                placeholder="mm/dd/yyyy"
                                :prepend-inner-icon="col.prependIcon"
                                clearable
                                :label="col.label"
                                readonly
                                v-on="on"
                            />
                        </template>
                        <v-calendar @dayclick="onDayClick($event, col.key)" />
                    </v-menu>

                    <template v-else-if="col.type === 'crop-retire'">
                        <p class="mb-1">
                            Crop Retire Program
                        </p>
                        <v-row align="center">
                            <v-col cols="6">
                                Annual Payment
                            </v-col>
                            <v-col cols="6">
                                <app-number-field
                                    v-model="formData.annualPayment"
                                    prefix="$"
                                    aria-label="Annual Payment"
                                    dense
                                />
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6">
                                Contract Expires
                            </v-col>
                            <v-col cols="6">
                                <v-menu
                                    v-model="contractExpiresMenu"
                                    offset-y
                                    :close-on-content-click="false"
                                    left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="formData.expires"
                                            v-bind="attrs"
                                            placeholder="mm/dd/yyyy"
                                            prepend-inner-icon="mdi-calendar"
                                            clearable
                                            readonly
                                            dense
                                            v-on="on"
                                        />
                                    </template>
                                    <v-calendar @dayclick="onDayClick($event, 'expires')" />
                                </v-menu>
                            </v-col>
                        </v-row>
                    </template>

                    <v-text-field
                        v-else
                        v-model="formData[col.key]"
                        :prepend-inner-icon="col.prependIcon"
                        :label="col.label"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { states } from '@/utils/constants';
import { assetMixin } from '@/mixins/assetMixin';
import AppSelect from '@/components/App/AppSelect';
import AppNumberField from '@/components/App/AppNumberField';
import { COMMERCIAL_FEATURES_FORM, LAND_FEATURES_FORM, RESIDENTIAL_FEATURES_FORM } from '@/constants/featuresForm';
import { Utils } from '@/utils';
import Calendar from 'v-calendar/lib/components/calendar.umd';

export default {
    name: 'AssetFeaturesForm',

    components: { AppNumberField, AppSelect, 'v-calendar': Calendar },

    mixins: [assetMixin],

    props: {
        features: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },

    data() {
        return {
            forms: {
                Residential: RESIDENTIAL_FEATURES_FORM,
                Commercial: COMMERCIAL_FEATURES_FORM,
                Land: LAND_FEATURES_FORM,
            },

            dataMenu: false,
            contractExpiresMenu: false,

            formData: {
                propertyType: null,
                propertySubType: [],
                beds: null,
                baths: null,
                squareFootage: null,
                yearBuilt: null,
                yearRenovated: null,
                lot: null,
                lotUnit: null,
                lotDimentions: null,
                brokerCoOp: null,
                termsAccepted: [],
                occupiedBy: [],
                ownership: [],
                possession: [],
                homeWarranty: null,
                style: [],
                stories: [],
                garageSpaces: null,
                basement: null,
                recentUpdates: [],
                securityFeatures: [],
                cooling: [],
                heating: [],
                electric: [],
                gas: [],
                water: [],
                sewer: [],
                county: null,
                zoning: [],
                subdivision: null,
                schoolDistrict: null,
                propertyTaxes: null,
                specialAssessmentTaxes: null,
                taxExemptions: [],
                hoaFee: null,
                hoaUnit: null,
                hoaAmenities: [],
                leaseType: [],
                leaseExpiration: null,
                totalBuildings: null,
                totalUnits: null,
                totalFloors: null,
                netOperatingIncome: null,
                occupancy: null,
                capRate: null,
                averageMonthlyRentUnit: null,
                tenantPays: [],
                inclusions: [],
                buildingClass: [],
                totalAcres: null,
                drylandAcres: null,
                irrigatedAcres: null,
                grassAcres: null,
                pastureFencedAcres: null,
                crpAcres: null,
                timberAcres: null,
                lotAcres: null,
                balanceOtherAcres: null,
                mineralRights: [],
                easements: [],
                survey: [],
                utilities: [],
                ponds: null,
                wells: null,
                fsaInformation: null,
                cropYieldHistory: null,
                soilProductivityRating: null,
                improvements: [],
                outbuildings: [],
                otherFeatures: [],
                fence: [],
                irrigationSystem: [],
                annualPayment: null,
                expires: null,
                roadFrontage: [],
                topography: [],
                wildlife: [],
                fish: [],
                recreation: [],
            },
            states,
        };
    },

    computed: {
        form() {
            return this.forms[this.$store.state.Asset.kind];
        },

        options() {
            return this.$store.getters['Asset/options'];
        },
    },

    created() {
        this.formData = {
            ...this.formData,
            ...this.features,
        };
    },

    methods: {
        onDayClick(ev, key) {
            this.formData[key] = `${ev.month}/${ev.day}/${ev.year}`;
            this.dataMenu = false;
            this.contractExpiresMenu = false;
        },
        setConfig() {
            const res = {
                ...this.formData,
            };

            const keys = Utils.extractKeysFromForm(this.form);

            if (this.$store.state.Asset.kind === 'Land') {
                keys.push('soilProductivityRating', 'fsaInformation', 'cropYieldHistory', 'annualPayment', 'expires');
            }

            const filtered = Utils.filterObject(res, keys);

            this.$store.commit('Asset/setFeatures', filtered);
        },
    },
};
</script>

<style scoped></style>